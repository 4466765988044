
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown.vue";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import ShadowControl from "@/components/Form/ShadowControl.vue";
import CreateUserDialog from "@/components/ui/Modal/Dialog/instances/CreateUserDialog.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import DeleteUserDialog from "@/components/ui/Modal/Dialog/instances/DeleteUserDialog.vue";
import { mapActions, useStore } from "vuex";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import AdminsTable from "@/components/Table/ts/instances/admins/AdminTable.vue";
import { pageFilter } from "@/components/Table/ts/hooks/useAppTable";
import { adminsFilterStatuses } from "@/enums/admins/adminsFilters";
import { computed } from "@vue/reactivity";
import { defineComponent, reactive, watch } from "vue";
import { IObjectKeys } from "@/interfaces/IObjectKeys";
import {
  IUseBaseAppLayout,
  useBaseAppLayout
} from "@/hooks/layout/useBaseAppLayout";
import { sortOrders } from "@/enums/main/sortOrders";
import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { useUsers } from "@/hooks/users/useUsers";
import { createUser } from "@/hooks/users/modules/createUser";
import { userTypes } from "@/api/interfaces/users/users.dto";
const filters = [adminsFilterStatuses.active, adminsFilterStatuses.inActive];

const AccountsSortKeys = {
  name: "name"
};

export const accountsSortKeys: IObjectKeys = [AccountsSortKeys.name];

export default defineComponent({
  name: "Admins",
  components: {
    AppTableController,
    ShadowControl,
    PageMainHeading,
    SelectDropdown,
    BtnContent,
    AdminsTable,
    AppButton,
    CreateUserDialog,
    DeleteUserDialog
  },

  setup() {
    const store = useStore();

    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });

    // Page layout refs
    const layoutRefs: IUseBaseAppLayout = useBaseAppLayout({
      initialSearchQuery: "",
      filterOptions: filters,
      onFiltersChange: () => requestAccountsHandler(true, fullFilter.value),
      onChangeSearchQuery() {
        changeViewConfigurationHandler({
          page: 1,
          perPage: viewConfiguration.perPage
        });
      }
    });

    watch(viewConfiguration, () =>
      requestAccountsHandler(true, fullFilter.value)
    );

    const { filter, searchQuery } = layoutRefs;

    const selectedFilterName = computed((): string => {
      if (!filter) return "";
      return (
        filter.find(({ selected }: pageFilter) => selected)?.name ||
        adminsFilterStatuses.active
      );
    });

    const fullFilter = computed(() => {
      return {
        text: searchQuery.value,
        sortSpec: selectedFilterName.value,
        sortUser: "Recently created",
        type: "admin",
        filterList: {}
      };
    });

    const options = {
      filter: fullFilter.value,
      viewConfiguration
    };
    const { requestAccountsHandler, changeViewConfigurationHandler } = useUsers(
      options
    );
    requestAccountsHandler();
    const createUserHandler = async (data: any) => {
      return await createUser(data, store);
    };
    return {
      ...layoutRefs,
      viewConfiguration,
      searchQuery,
      accountsList: computed(() => store.getters.accountsList),
      totalAccountsCount: computed(() => store.getters.totalAccounts),
      createUserHandler,
      requestAccountsHandler,
      changeViewConfigurationHandler
    };
  },
  data() {
    return {
      createAdminDialogOpened: false,
      deleteUserDialogOpened: false,
      userInfo: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        avatar: ""
      },
      sortConfig: {
        byName: { order: sortOrders.turnOff, keyName: "fullName" }
      },
      isLoading: false
    };
  },
  computed: {
    emptyText(): string {
      if (!this.searchQuery) {
        return "There are no admins created yet!";
      }

      return `No admins found by request "${this.searchQuery}"`;
    }
  },
  methods: {
    ...mapActions(["setAppLoading", "showErrorNotification"]),
    toggleCreateAdminDialog(status = true) {
      this.createAdminDialogOpened = status;
    },
    deleteUserHandler(id: number) {
      const account: IUserAccount | null =
        this.accountsList.find(
          ({ id: accountId }: IUserAccount) => id === accountId
        ) || null;

      if (account !== null) {
        const { email, firstName, lastName, image: avatar } = account;
        this.userInfo = { id, email, firstName, lastName, avatar };

        this.deleteUserDialogOpened = true;
      }
    },

    goToUser(id: number) {
      this.$router.push({ path: "/admin", query: { id: id.toString() } });
    },

    async deleteUser() {
      const { success, message } = await this.$store.dispatch("removeUser", {
        id: this.userInfo.id
      });

      if (!success) {
        await this.showNotification(message);
      }
      await this.requestAccountsHandler?.();
      this.deleteUserDialogOpened = false;
    },

    async endCreateAdmin(data: any): Promise<boolean> {
      this.isLoading = true;
      const success = await this.createUserHandler({
        ...data,
        type: userTypes.admin
      });

      this.isLoading = false;
      if (success) {
        this.toggleCreateAdminDialog(false);
      }
      return success;
    },

    showNotification(message: string): void {
      this.$store.dispatch("showErrorNotification", message);
    }
  }
});
